import "../FiltersDropdown/FiltersDropdown.css";
import setaBaixo from "../../../imgs/setaBaixoRoxa.svg";
import setaCima from "../../../imgs/setaCimaPreta.svg";

const FiltersDropdown = ({ title, isExpanded, setExpanded }) => {
    const toggleDropdown = () => {
        if (isExpanded) {
            setExpanded(null);
        } else {
            setExpanded(title);
        }
    };

    return (
        <div className="dropdown-box">
            <div
                className={`dropdown-title ${isExpanded ? "active" : ""}`}
                onClick={toggleDropdown}
            >
                <div>{title}</div>
                <img src={isExpanded ? setaBaixo : setaCima} alt="" />
            </div>
        </div>
    );
};

export default FiltersDropdown;