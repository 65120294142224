import "../Filters/Filters.css";
import FiltersDropdown from "../Filters/FiltersDropdown/FiltersDropdown";
import { useState } from "react";
import selectedImg from "../../imgs/Selected.svg";

const Filters = ({ filters, onFilterChange }) => {
  const [expanded, setExpanded] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleFilterSelection = (title, filter) => {
    setSelectedOptions((prev) => {
      const currentSelections = prev[title] || [];
      if (currentSelections.includes(filter)) {
        const updatedSelections = currentSelections.filter(
          (item) => item !== filter
        );
        const newState = { ...prev, [title]: updatedSelections };
        onFilterChange(newState);
        return newState;
      } else {
        const updatedSelections = [...currentSelections, filter];
        const newState = { ...prev, [title]: updatedSelections };
        onFilterChange(newState);
        return newState;
      }
    });
  };

  return (
    <div className="filtersContainer">
      <div className="filters-box">
        {filters.map((filter, index) => (
          <FiltersDropdown
            key={index}
            title={filter.title}
            options={filter.filters}
            isExpanded={expanded === filter.title}
            setExpanded={setExpanded}
          />
        ))}
      </div>
      {expanded && (
        <div className="filters-inside-container">
          {filters
            .find((filter) => filter.title === expanded)
            .filters.map((filter, index) => (
              <div
                className="tipoooo"
                key={index}
                onClick={() => handleFilterSelection(expanded, filter)}
              >
                <div className="circle">
                  {selectedOptions[expanded]?.includes(filter) && (
                    <img
                      src={selectedImg}
                      alt="Selected"
                      className="selected-img"
                    />
                  )}
                </div>
                {filter}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Filters;
