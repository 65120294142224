import "./card.css";

const CardEstagios = ({ tipoEstagio, img, link, descricao }) => {
  const formattedTipoEstagio = Array.isArray(tipoEstagio)
    ? tipoEstagio.join(" / ") 
    : tipoEstagio;

  return (
    <div className="cardEstagiosContainer">
      {formattedTipoEstagio && <p className="tipoEstagio">{formattedTipoEstagio}</p>}
      <img src={img} alt="Logo da empresa" className="logoEmpresaEstagio" />
      {descricao && <p className="descricaoEstagio">{descricao}</p>}
      {link && (
        <a target="_blank" rel="noreferrer" href={link} className="linkEstagio">
          {/* noreferrer -> segurança*/}+ INFORMAÇÕES
        </a>
      )}
    </div>
  );
};

export default CardEstagios;