import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";

import "../Partners/Partners.css"

import parceiros from "../../imgs/PARCEIROS.svg"

import bolasMobile1 from "../../imgs/bolasMobilePartners1.svg"  //esquerda
import bolasMobile2 from "../../imgs/bolasMobilePartners2.svg"  //direita

import bolasPartners1 from "../../imgs/bolasPartners1.svg"  //direita
import bolasPartners2 from "../../imgs/bolasPartners2.svg"  //esquerda

const Partners = ({ images, images2, images3 }) => {

    console.log(images)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 770 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 770 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        // Limpa o listener do evento de redimensionamento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="homepage-partners">
            <img src={bolasPartners1} alt="" className="bolasPartners1"></img>
            <img src={bolasPartners2} alt="" className="bolasPartners2"></img>
            <img src={bolasMobile1} alt="" className="bolasMobile1"></img>
            <img src={bolasMobile2} alt="" className="bolasMobile2"></img>

            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
            <div className="partners-title">
                    <img src={parceiros} alt="" className="parceiros-img"></img>
                </div>
            <div className="partners-container">
                    {images.map((img, index,index2) => (
                        <div key={index}>
                            <img key={index2} src={img} alt='' />
                        </div>
                    ))
                    }
            </div>
            <div className="div-title">
                <h2 className="label-title">Institucionais</h2>
            </div>
            <div className="partners-container">
                    {images2.map((img, index,index2) => (
                        <div key={index}>
                            <img key={index2} src={img} alt='' />
                        </div>
                    ))
                    }
            </div>
            <div className="div-title">
                <h2 className="label-title">Comida</h2>
            </div>
            <div className="partners-container">
                    {images3.map((img, index,index2) => (
                        <div key={index}>
                            <img key={index2} src={img} alt='' />
                        </div>
                    ))
                    }
            </div>
            <Footer />
        </div>
    )
}

export default Partners;