import "../Internships/Internships.css";
import Navbar from "../../components/Navbar/Navbar";
import NavbarMobile from "../../components/Navbar/NavbarMobile/NavbarMobile";
import { useState, useEffect } from "react";

import CardEstagios from "../../components/CardEstagios/card";
import estagiosImg from "../../imgs/ESTAGIOS.png";
import empresasImg from "../../imgs/EMPRESAS.png";
import Footer from "../../components/Footer/Footer";
import Filters from "../../components/Filters/Filters";
import bolasEstagios from "../../imgs/bolasEstagios.png";
import bolasEstagiosMobileLeft from "../../imgs/bolasEstagiosEsquerda.png";
import bolasEstagiosMobileRight from "../../imgs/bolasEstagiosDireita.png";

const Internships = ({ filters, estagios }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 770 ? 1 : 3);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [filteredEstagios, setFilteredEstagios] = useState(estagios);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 770 ? 1 : 3);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        let filtered = estagios;

        // Filtrar por AREAS
        if (selectedFilters["AREAS"] && selectedFilters["AREAS"].length > 0) {
            filtered = filtered.filter((group) =>
                Object.values(group).some((internships) =>
                    internships.some((internship) => {
                        const areas = Array.isArray(internship.area)
                            ? internship.area
                            : [internship.area];
                        return areas.some((area) =>
                            selectedFilters["AREAS"].includes(area)
                        );
                    })
                )
            );
        }

        // Filtrar por EMPRESAS
        if (selectedFilters["EMPRESAS"] && selectedFilters["EMPRESAS"].length > 0) {
            filtered = filtered.filter((group) =>
                Object.keys(group).some((companyName) =>
                    selectedFilters["EMPRESAS"].includes(companyName)
                )
            );
        }

        // Filtrar por DURAÇÃO
        if (selectedFilters["DURAÇÃO"] && selectedFilters["DURAÇÃO"].length > 0) {
            filtered = filtered.filter((group) =>
                Object.values(group).some((internships) =>
                    internships.some((internship) => {
                        const durations = Array.isArray(internship.time || internship.tempo)
                            ? internship.time || internship.tempo
                            : [internship.time || internship.tempo];
                        return durations.some((duration) =>
                            selectedFilters["DURAÇÃO"].includes(duration)
                        );
                    })
                )
            );
        }

        // Filtrar por TIPO
        if (selectedFilters["TIPO"] && selectedFilters["TIPO"].length > 0) {
            filtered = filtered.filter((group) =>
                Object.values(group).some((internships) =>
                    internships.some((internship) => {
                        const tipos = Array.isArray(internship.tipo)
                            ? internship.tipo
                            : internship.tipo
                            ? [internship.tipo]
                            : [];
                        return tipos.some((tipo) =>
                            selectedFilters["TIPO"].includes(tipo)
                        );
                    })
                )
            );
        }

        setFilteredEstagios(filtered);
    }, [selectedFilters, estagios]);

    const areFiltersActive = Object.values(selectedFilters).some(
        (filterArray) => filterArray.length > 0
    );

    return (
        <div className="internships-webpage">
            {isMobile === 1 ? <NavbarMobile /> : <Navbar />}
            <div className="EstagiosTitle">
                <img src={estagiosImg} alt="Estágios" />
            </div>
            <div className="filters">
                <Filters filters={filters} onFilterChange={setSelectedFilters} />
            </div>
            <div className="titleContainer">
                <img src={empresasImg} alt="Empresas" className="empresasImg" />
            </div>

            <div className="internshipsContainer">
                {!areFiltersActive && (
                    <>
                        <img src={bolasEstagios} alt="Bolas" className="bolasEstagios" />
                        <img
                            src={bolasEstagiosMobileLeft}
                            alt="Bolas"
                            className="bolasEstagiosMobile bolasEstagiosMobileLeft"
                        />
                        <img
                            src={bolasEstagiosMobileRight}
                            alt="Bolas"
                            className="bolasEstagiosMobile bolasEstagiosMobileRight"
                        />
                    </>
                )}

                {filteredEstagios.map((internshipGroup, groupIndex) =>
                    Object.entries(internshipGroup).map(([companyName, internships]) => (
                        <div key={groupIndex} className="estagio">
                            <h1>{companyName}</h1>
                            <div className="manyEstagiosContainer" id={companyName}>
                                {internships.map((internship, index) => (
                                    <CardEstagios
                                        key={index}
                                        tipoEstagio={internship.tipo}
                                        img={internship.img}
                                        link={internship.link}
                                        descricao={internship.description}
                                    />
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
            <Footer></Footer>
        </div>
    );
};

export default Internships;